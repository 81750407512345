<template>
    <form @submit="submit">
        <loading
              :active.sync="isLoading"
              :is-full-page="false"
              :color="this.$config.LOADER.color"
              :opacity="this.$config.LOADER.opacity"
              :background-color="this.$config.LOADER.backgroundColor"
              :z-index="10000"
            ></loading>
        <b-form-group
              id="input-group-customer"
              label="Зээл"
            >
            <multiselect
                v-model="form.accounts"
                :loading="isLoadingAccounts"
                :options="account_list"
                :searchable="true"
                :multiple="true"
                placeholder="Зээл сонгох"
                label="accountNumber"
                :hide-selected="true"
                :close-on-select="false"
                track-by="id"
            >
                <template slot="tag" slot-scope="{ option, remove }">
                <span class="multiselect__tag">
                    <span>
                        <strong class="text-black">{{ option.loanAmount + "₮" }}</strong>
                    {{ " авсан: (" + option.loanDate + ")" }}
                    </span>
                    <i
                    aria-hidden="true"
                    tabindex="1"
                    @keypress.enter.prevent="remove(option)"
                    @mousedown.prevent="remove(option)"
                    class="multiselect__tag-icon"
                    ></i>
                </span>
                </template>
                <template slot="option" slot-scope="props">
                <span>
                    <strong class="text-black">{{ props.option.loanAmount + "₮" }}</strong>
                    {{ " авсан: (" + props.option.loanDate + ")" }} 
                </span>
                </template>
            </multiselect>
        </b-form-group>

        <b-form-group
              id="input-group-customer"
              label="Засварлар огноо"
            >
            <date-picker
                v-model="form.repair_date"
                :config="{ format: 'YYYY-MM-DD', useCurrent: false }"
                required
                placeholder="Төлсөн огноо"
              ></date-picker>
        </b-form-group>

        <b-form-group
              id="input-group-customer"
              label="Шалтгаан"
            >
            <b-textarea required v-model="form.descr" placeholder="Тайлбар бичнэ үү"></b-textarea>
        </b-form-group>

        <b-form-group class="mb-0">
            <b-button variant="primary" type="submit"> Хадгалах</b-button>
        </b-form-group>
    </form>
</template>

<script>
export default {
  name: "LoanCloseRepair",
  props: ["customer"],
  data: function() {
    return {
        form: {
            accounts: [],
            repair_date: null,
            descr: ''
        },
      isLoading: false,
      isLoadingAccounts: false,
      account_list: []
    };
  },
  mounted() {
    this.loadData(),
    this.$data.form = {
        accounts: [],
        repair_date: null,
        descr: ''
    }
  },
  methods: {
    loadData: function() {
      this.checkSession();
      this.isLoadingAccounts = true;
      this.$data.account_list = []
      this.$http
        .get(
          this.$config.API_URL + "CustomerWebService/get_active_loan_list",
          {
            params: {
                customer_id: this.customer.id
            },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true
          }
        )
        .then(
          (response) => {
              console.log(response)
            this.isLoadingAccounts = false;
            this.$data.account_list = response.body.data;
          },
          (response) => {
            this.isLoadingAccounts = false;
          }
        );
    },
    submit: function() {
        if(this.$data.form.accounts.length > 0) {
            this.isLoading = true;
            let accountIds = []
            for(let i in this.$data.form.accounts) {
                accountIds.push(this.$data.form.accounts[i].id)
            }
            this.$http
            .post(
                this.$config.API_URL + "RepairWebService/repair_loan_close",
                { data: JSON.stringify({
                    customerId: this.customer.id,
                    loanIds: accountIds,
                    repairDate: this.form.repair_date,
                    description: this.form.descr
                }) },
                { headers: this.$store.getters.httpHeader, emulateJSON: true }
            )
            .then(
                (response) => {
                this.isLoading = false;
                if (response.body.responseResultType == "SUCCESS") {
                    this.showToast(
                    "Амжилттай",
                    "Амжилттай хадгалагдлаа",
                    "success"
                    );
                    this.onReset(evt);
                } else {
                    this.showToast("Алдаа", "Хадгалах үед алдаа гарлаа", "danger");
                }
                },
                (response) => {
                this.isLoading = false;
                }
            );
        } else {
            this.showToast("Анхаар", "Зээл сонгоогүй байна", "warning");
        }
    }
  },
};
</script>
