<template>
  <div class="animated fadeIn">
    <b-row class="justify-content-md-center">
      <b-col sm="8">
        <b-form @submit.stop.prevent="onSubmit" v-if="show">
          <b-card header-tag="header" footer-tag="footer">
            <loading
              :active.sync="isLoading"
              :is-full-page="false"
              :color="this.$config.LOADER.color"
              :opacity="this.$config.LOADER.opacity"
              :background-color="this.$config.LOADER.backgroundColor"
              :z-index="10000"
            ></loading>
            <h5 slot="header" class="mb-0">
              <span>Засварын гүйлгээ бүртэх</span>
            </h5>
            <div>
                    <b-form-group
                        id="input-group-payDescription"
                        label="Утасны дугаар"
                        label-for="input-username"
                    >
                        <money
                        id="input-username"
                        class="form-control"
                        :class="{
                            'border-danger': !selectCustomer,
                            'border-primary': selectCustomer,
                        }"
                        v-model="form.username"
                        type="text"
                        required
                        placeholder="Утасны дугаар"
                        suffix=""
                        thousands=""
                        :precision="0"
                        ></money>
                    </b-form-group>
                </div>
            <div v-if="selectCustomer">
                    <b-alert show>
                        <b-button
                            :to="{
                            name: 'admin.customer.detail',
                            params: { id: selectCustomer.id },
                            }"
                            target="_blank"
                            class="pull-right "
                            size="sm"
                            variant="info"
                            >Дэлгэрэнгүй</b-button
                        >
                        <h4 class="mb-0">{{ selectCustomer.lastname }} {{ selectCustomer.firstname }} /{{ selectCustomer.registerNumber }}/ </h4>
                        
                    </b-alert>
                    <b-form-group
                        id="input-"
                        label="Төрөл"
                        label-for="input-payDescription"
                    >
                        <b-form-select
                        id="input-payDescription"
                        v-model="form.repair_type"
                        required
                        :options="repair_types"
                        ></b-form-select>
                    </b-form-group>
            </div>
            <div v-else>
                <b-alert variant="warning" show>
                    Хэрэглэгч байхгүй байна
                </b-alert>
            </div>
            <div v-if="form.repair_type">
                <LoanCloseRepair v-if="form.repair_type === 'LOAN_CLOSE_REPAIR'" :customer="selectCustomer"></LoanCloseRepair>
                <LoanClosedRepair v-if="form.repair_type === 'LOAN_CLOSED_REPAIR'" :customer="selectCustomer"></LoanClosedRepair>
                <OverdueRepair v-if="form.repair_type === 'OVERDUE_REPAIR'" :customer="selectCustomer"></OverdueRepair>
                <ReturnRepair v-if="form.repair_type === 'RETURN_REPAIR'" :customer="selectCustomer"></ReturnRepair>
                <DoubleTakeRepair v-if="form.repair_type === 'DOUBLE_TAKE_REPAIR'" :customer="selectCustomer"></DoubleTakeRepair>
                <BalanceRepair v-if="form.repair_type === 'BALANCE_REPAIR'" :customer="selectCustomer"></BalanceRepair>
            </div>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import ReturnRepair from './Repair/ReturnRepair.vue'
import LoanClosedRepair from './Repair/LoanClosedRepair.vue'
import LoanCloseRepair from './Repair/LoanCloseRepair.vue'
import OverdueRepair from './Repair/OverdueRepair.vue'
import DoubleTakeRepair from './Repair/DoubleTakeRepair.vue'
import BalanceRepair from './Repair/BalanceRepair.vue'

export default {
  name: "LoanRepayments.repair",
  components: { ReturnRepair, LoanClosedRepair, LoanCloseRepair, OverdueRepair, DoubleTakeRepair, BalanceRepair },
  data: function() {
    return {
        form: {
            username: "",
            repair_type: null
        },
        selectCustomer: null,
        isLoading: false,
        isLoadingCustomer: false,
        isLoadingAccounts: false,
        show: true,
        repair_types: [
            {
                value: "LOAN_CLOSED_REPAIR",
                text: 'Илүү хүү бодогдож зээл хаагдсан'
            },
            {
                value: "LOAN_CLOSE_REPAIR",
                text: 'Бага дүнтэй зээл хаах'
            },
            {
                value: "OVERDUE_REPAIR",
                text: 'Илүү бодогдсон торгууль/нэмэгдүүлсэн хүү'
            },
            {
                value: "RETURN_REPAIR",
                text: 'Илүү төлөлтийн буцаалт'
            },
            {
                value: "DOUBLE_TAKE_REPAIR",
                text: 'Давхар олголтын буцаалт'
            },
            {
                value: "BALANCE_REPAIR",
                text: 'Илүү/дутуу төлөлт бүртгэх'
            }
        ]
    };
  },
  created: function() {
    this.checkSession();
  },
  watch: {
      "form.username": {
      handler(username) {
        this.$data.username = username.toString();
        this.$data.selectCustomer = null
        if (username && username > 80000000) {
            this.getCustomerShort(username);
        }
      },
      deep: true,
    },
    "isLoading": {
        handler(isLoading) {
            console.log(isLoading)
        },
        deep: true
    }
  },
  methods: {
    onSubmit: function() {

    },
    getCustomerShort: function(phone) {
      this.isLoading = true;
      this.$data.selectCustomer = null;
      this.$http
        .get(
          this.$config.API_URL +
            "CustomerWebService/get_customer_short_info_by_register",
          {
            params: {
              phone: phone,
            },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            this.isLoading = false;
            if (response.data) {
              this.$data.selectCustomer = response.data;
            } else {
              this.showToast(
                "Анхаар",
                phone + " утасны дугаартай хэрэгдэгчийн мэдээлэл байхгүй байна",
                "warning"
              );
            }
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
  },
};
</script>
